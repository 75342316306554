import React from "react";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import GradientLine from "../Constant/GradientLine";

const headCells = [
  { id: "name", numeric: false, label: "Mining Pools", tooltip: "Pool name" },
  { id: "locations", numeric: false, label: "Locations", tooltip: "Server locations around the world" },
  { id: "scheme", numeric: false, label: "Scheme", tooltip: "Supported payment schemes" },
  { id: "minimum-pay", numeric: true, label: "Min. Pay", tooltip: "The minimum payout available" },
  { id: "maximum-pay", numeric: true, label: "Max. Pay", tooltip: "The maximum payout available" },
  { id: "pay-interval", numeric: true, label: "Pay Interval", tooltip: "The interval payouts are made" },
  { id: "fee", numeric: true, label: "Fee", tooltip: "Percentage the pool keeps from rewards" },
  { id: "status", numeric: false, label: "Status", tooltip: "Pool availability" },
];

const CoinSubPoolsTableHead = props => {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={classes.tableRow}>
        {headCells.map(headCell => {
          return (
            <TableCell
              className={
                headCell.id === "name"
                  ? clsx(classes.tableCell, classes.firstTableCell)
                  : headCell.id === "status"
                  ? clsx(classes.tableCell, classes.lastTableCell)
                  : clsx(classes.tableCell, classes.normalTableCell)
              }
              key={headCell.id}
              align={headCell.id === "status" ? "right" : "left"}
              padding="none"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                <Tooltip title={headCell.tooltip} placement="top" arrow>
                  <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
                    {headCell.label}
                  </Typography>
                </Tooltip>
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
        {/* <TableCell className={clsx(classes.tableCell, classes.firstTableCell)} align="left" sortDirection={false}>
          <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
            {coin.name} Mining Pools
          </Typography>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="Server locations around the world" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Locations
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="Supported payment schemes" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Scheme
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="The minimum payout available" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Min. Pay
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="The maximum payout available" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Max. Pay
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="The interval payouts are made" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Pay Interval
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.normalTableCell)} align="left" sortDirection={false}>
          <Tooltip title="Percentage the pool keeps from rewards" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Fee
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.lastTableCell)} align="right" sortDirection={false}>
          <Tooltip title="Pool availability" placement="top" arrow>
            <Typography variant="subtitle1" style={{ fontWeight: "600" }}>
              Status
            </Typography>
          </Tooltip>
        </TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0, borderBottom: "unset" }}>
          <GradientLine />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default CoinSubPoolsTableHead;
