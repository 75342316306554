import React from "react";
// import { graphql } from "gatsby";

import CoinProfile from "./CoinProfile";
import CoinSecondProfile from "./CoinSecondProfile";
import CoinSubPools from "./CoinSubPools";
import Root from "../Root";
import SEO from "../seo";
import NavajoAboveTable from "../Navajo/NavajoAboveTable";
import BreadcrumsControls from "../Constant/BreadcrumsControls";
import NavajoZillaBottom from "../Navajo/NavajoZillaBottom";

// export const query = graphql`
//   query($slug: String!) {
//     allSubpoolsJson(filter: { isAlive: { eq: true }, coin: { slug: { eq: $slug } } }) {
//       nodes {
//         id
//         servers {
//           abbreviation
//           city
//           country
//           continent
//           latitude
//           longitude
//           url
//         }
//         ports {
//           description
//           difficulty
//           port
//         }
//         poolProfile {
//           numberOfSubPools
//           name
//           slug
//         }
//         paymentInformation {
//           infoUrl
//           paymentInterval
//           paymentScheme
//           paymentThreshold {
//             max
//             min
//           }
//           fee {
//             feeType
//             max
//             min
//           }
//         }
//         mergedCoins {
//           abbreviation
//           name
//           slug
//         }
//         locations
//         isMerged
//         isAlive
//         coin {
//           abbreviation
//           algorithm {
//             name
//             slug
//           }
//           name
//           numberOfSubPools
//           slug
//         }
//         slug
//         url
//       }
//     }
//     coinsJson(slug: { eq: $slug }) {
//       abbreviation
//       coinCap {
//         id
//         isAvailable
//       }
//       description
//       links {
//         name
//         platform
//         url
//       }
//       name
//       numberOfSubPools
//       slug
//       algorithm {
//         name
//         slug
//         type
//       }
//     }
//   }
// `;

const Coin = ({ pageContext }) => {
  const { coin } = pageContext;

  const breadcrums = [
    { to: "/", page: "Home" },
    { to: "/", page: "Coins" },
  ];

  return (
    <Root>
      <SEO
        title={`${coin.data.name} (${coin.data.abbreviation}) Mining Pools ${coin.data.algorithm.name}`}
        description={`View the best ${coin.data.name} mining pools (BTC) with ${coin.data.algorithm.name} PoW algorithm. Checking the pool fee, payment scheme or minimum payout.`}
      />
      <BreadcrumsControls breadcrums={breadcrums} currentPage={`${coin.data.name}`} />
      <CoinProfile coin={coin.data} />
      <NavajoAboveTable />
      <CoinSubPools coin={coin.data} subPools={coin.data.subPools} />
      <CoinSecondProfile coin={coin.data} />
      <NavajoZillaBottom />
    </Root>
  );
};

export default Coin;
