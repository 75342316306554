import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReadMoreReact from "read-more-react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import WebIcon from "@material-ui/icons/Web";
import ExploreIcon from "@material-ui/icons/Explore";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import RedditIcon from "@material-ui/icons/Reddit";
import ForumIcon from "@material-ui/icons/Forum";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkIcon from "@material-ui/icons/Link";

import OutboundLink from "../Constant/OutboundLink";

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.default,
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "80px",
    },
  },
  gridRoot: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "unset",
    },
  },
  gridItem: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50%",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontWeight: "700",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "1.2",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
      lineHeight: "1.3",
    },
  },
  description: {
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  readMore: {
    fontWeight: "500",
    lineHeight: "1.6",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  urlLink: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CoinSecondProfile = props => {
  const { coin } = props;
  const classes = useStyles(props);

  const getButtonLink = (link, index, icon) => {
    return (
      <OutboundLink
        key={index}
        href={link.url}
        category="Coin Profile"
        action="Link Click"
        label={`${coin.name}: ${link.name} - ${link.url}`}
        value={20}
        style={{ textDecoration: "none", marginRight: 10 }}
      >
        <Button
          style={{ textTransform: "none", fontWeight: "700", marginBottom: 10 }}
          variant="contained"
          color="primary"
          size="large"
          className={classes.urlLink}
          startIcon={icon}
          disableElevation
        >
          {link.name}
        </Button>
      </OutboundLink>
    );
  };

  const getLinks = () => {
    if (coin.links.length === 0) {
      return null;
    } else {
      return coin.links.map((link, index) => {
        switch (link.platform) {
          case "website":
            return getButtonLink(link, index, <WebIcon />);
          case "explorer":
            return getButtonLink(link, index, <ExploreIcon />);
          case "twitter":
            return getButtonLink(link, index, <TwitterIcon />);
          case "facebook":
            return getButtonLink(link, index, <FacebookIcon />);
          case "discord":
            return getButtonLink(link, index, <LinkIcon />);
          case "telegram":
            return getButtonLink(link, index, <TelegramIcon />);
          case "reddit":
            return getButtonLink(link, index, <RedditIcon />);
          case "bitcointalk":
            return getButtonLink(link, index, <ForumIcon />);
          case "github":
            return getButtonLink(link, index, <GitHubIcon />);
          default:
            return getButtonLink(link, index, <LinkIcon />);
        }
      });
    }
  };

  const getDescription = () => {
    if (coin.description === "") {
      return `${coin.name} (${coin.abbreviation}) is a cryptocurrency that uses the ${coin.algorithm.name} algorithm and has a total of ${coin.numberOfSubPools} mining pools.`;
    } else {
      return `${coin.description} ${coin.name} has ${coin.numberOfSubPools} mining pools and uses the ${coin.algorithm.name} algorithm.`;
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid className={classes.gridRoot} container spacing={3}>
          <Grid className={classes.gridItem} item xs={6}>
            <Typography className={classes.headerTitle} variant="h2">
              What is {coin.name} ({coin.abbreviation})?
            </Typography>
            <Typography className={classes.readMore} variant="subtitle1">
              <ReadMoreReact text={getDescription()} min={180} ideal={220} max={1000} readMoreText="read more" />
            </Typography>
          </Grid>
          <Grid className={classes.gridItem} item xs={6}>
            <Typography className={classes.headerTitle} variant="h2">
              {coin.name} ({coin.abbreviation}) Links
            </Typography>
            <div>{getLinks()}</div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CoinSecondProfile;
